import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthPage } from '@/actions/content'
import { setAccountType } from '@/actions/user'
import PageLoader from '@/components/Common/Loader/PageLoader'
import SelectAccountType from '@/components/Auth/Form/SelectAccountType'
import BlockUser from '@/components/User/BlockUser'
import BlockEditUser from '@/components/User/BlockEditUser'
import BlockAbout from '@/components/User/BlockAbout'
import BlockEditAbout from '@/components/User/BlockEditAbout'
import BlockInterests from '@/components/User/BlockInterests'
import BlockEditInterests from '@/components/User/BlockEditInterests'
import BlockWorks from '@/components/User/BlockWorks'
import BlockEditWorks from '@/components/User/BlockEditWorks'
import BlockProjects from '@/components/User/BlockProjects'
import BlockEditProjects from '@/components/User/BlockEditProjects'
import BlockAchievements from '@/components/User/BlockAchievements'
import BlockEditAchievements from '@/components/User/BlockEditAchievements'
import BlockSkills from '@/components/User/BlockSkills'
import BlockEditSkills from '@/components/User/BlockEditSkills'

const UserDashboardPage = ({ user, onLayoutChanged }) => {
	const dispatch = useDispatch()

    const [loaded, setLoaded] = useState(false)

	const [blocks, setBlocks] = useState([
		{ name: 'user', showComponent: BlockUser, editComponent: BlockEditUser, edit: false },
		{ name: 'about', showComponent: BlockAbout, editComponent: BlockEditAbout, edit: false },
		{ name: 'interests', showComponent: BlockInterests, editComponent: BlockEditInterests, edit: false },
		{ name: 'works', showComponent: BlockWorks, editComponent: BlockEditWorks, edit: false },
		{ name: 'projects', showComponent: BlockProjects, editComponent: BlockEditProjects, edit: false },
		{ name: 'achievements', showComponent: BlockAchievements, editComponent: BlockEditAchievements, edit: false },
		{ name: 'skills', showComponent: BlockSkills, editComponent: BlockEditSkills, edit: false },
	])

	const authPage = useSelector(state => state.content.authPage)

	const [texts, setTexts] = useState({ title: '', subtitle: '', connected_pages: {} })

	useEffect(() => {
		dispatch(getAuthPage('/register'))
	}, [dispatch])

	useEffect(() => {
		if (authPage) {
			const { connected_pages } = authPage

			setTexts({ ...texts, connected_pages })
		}
	}, [authPage])

	const [accType, setAccType] = useState(null)

	const onAccountTypeSelected = async (account_type) => {
		await dispatch(setAccountType({ account_type }))
		setAccType(account_type)
		setLayout(account_type)
	}

	const setLayout = (account_type) => {
		if (account_type !== null) {
			onLayoutChanged('profile')
		} else {
			onLayoutChanged('auth')
		}
	}

    useEffect(() => {
        if (user && authPage) {
			setAccType(user.account_type)
        	setTimeout(() => setLoaded(true), 500)
        }
    }, [user, authPage])

	const onEdit = (blockName) => {
		setBlocks((prevBlocks) =>
			prevBlocks.map((block) =>
				block.name === blockName ? { ...block, edit: true } : block
			)
		)
	}

	const onSave = (blockName) => {
		setBlocks((prevBlocks) =>
			prevBlocks.map((block) =>
				block.name === blockName ? { ...block, edit: false } : block
			)
		)
	}

	return (
		<>
			<PageLoader loaded={loaded} />
			{!accType && <div className="services-section">
				<div className="container">
					<SelectAccountType
						texts={texts.connected_pages['select-account-type']}
						onAccountTypeSelected={onAccountTypeSelected}
					/>
				</div>
			</div>}
			{accType && blocks.map((block, index) => {
				const { name, showComponent: ShowComponent, editComponent: EditComponent, edit } = block
				if (edit) return <EditComponent key={index} user={user} onSave={() => onSave(name)} />
				return <ShowComponent key={index} user={user} isOwn={true} onEdit={() => onEdit(name)} />
			})}
		</>
	)
}

export default UserDashboardPage
